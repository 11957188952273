.ac-footer {

    &__social-link {

        img {
            height: var(--spacing-scale-4x);
        }
    }

    .br-item.header {
        cursor: default !important;

        &:hover {
            background: transparent;
        }
    }
}

@media (max-width: 991px) {

    .ac-footer {

        .br-item.header {
            cursor: pointer !important;

            &:hover {
                background-image: linear-gradient(rgba(var(--interactive-rgb),var(--hover)),rgba(var(--interactive-rgb),var(--hover)));
            }
        }
    }
}
