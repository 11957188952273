.ac-advanced-search {

    &__top-action {
        text-align: right;
    }

    &__bi {
        position: relative;
        padding-top: 56.25%;

        & + .ac-program__video {
            margin-top: 30px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

@media (max-width: 991px) {
    .ac-advanced-search {

        &__top-action {
            text-align: center;
        }
    }
}
