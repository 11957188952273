.ac-program-faq {

    a {
        cursor: pointer !important;
    }

    &__content {

        & + .ac-program-faq__content {
            margin-top: 40px;
        }

    }

    &__inner-content {
        margin-left: 60px;
        margin-top: 40px;
    }

    &__title {
        font-size: 17px;
        text-transform: uppercase;

        & + .ac-program-faq__text {
            margin-top: 20px;
        }
    }

    &__text {

    }

    &__group-container {
    }

    &__group {
        position: relative;
        padding-top: 120px;
        padding-bottom: 40px;
    }

    &__group-title {
        font-weight: 600;
        font-size: 29.3px;
        color: var(--blue-vivid-50);

        & + .ac-program-faq__content {
            margin-top: 40px;
        }
    }

    &__back {
        position: absolute;
        right: 0;
        bottom: 0;
        text-decoration: none !important;
    }

    &__menu-container {
        width: inherit;
    }

    &__menu {
        display: flex;
        position: relative;
        flex-direction: column;
        background: var(--pure-0);
        width: 100%;

        &--fixed {
            position: fixed;
            top: 160px;
            z-index: 9;
        }

        a {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: var(--spacing-scale-2x) var(--spacing-scale-2x) var(--spacing-scale-2x) 10px;
            text-decoration: none !important;

            span {
                font-size: var(--font-size-scale-up-01);
            }

            &:first-child {
                border-top: 1px solid var(--color-secondary-04);
            }

            &:last-child {
                border-bottom: 1px solid var(--color-secondary-04);
            }

            & + a {
                border-top: 1px solid var(--color-secondary-04);
            }
        }
    }
}
