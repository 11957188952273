.ac-alert {
    position: fixed;
    display: none;
    justify-content: center;
    //align-items: center;
    z-index: 9999;
    min-height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.4);
    padding: 0 20px;

    &--active {
        display: flex;
    }

    //&__modal {
    //    display: none;
    //
    //    &--active {
    //        display: block;
    //    }
    //}

    .br-modal {
        position: relative;
        background: var(--pure-0);
        max-width: 500px;
        color: var(--color);
        max-height: 600px;
        height: 100%;
        overflow-y: auto;
        margin-top: 120px;

        .br-modal-header {
            //position: fixed;
            //max-width: calc(500px - var(--spacing-scale-6x));
        }

        .br-modal-title {
            display: flex;
            flex-direction: column;

            .ac-alert__step {
                font-size: 12px;
                font-weight: normal;
            }
        }

        .br-modal-body {
            //padding-top: 100px;
        }
    }
}

@media (max-width: 576px) {

    .ac-alert {

        .br-modal {
            padding: 20px;
        }
    }
}
