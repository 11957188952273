.ac-calendar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 10px;
    color: var(--text-color);
    background: var(--pure-0);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    height: 200px;
    min-height: 200px;
    width: 220px;
    min-width: 220px;
    border: solid 2px transparent;
    text-decoration: none !important;

    &:hover {
        background: var(--pure-0) !important;
    }

    &--pointer {
        cursor: pointer;
    }

    &--active {
        border: solid 2px var(--blue-vivid-50);

        .ac-calendar__status {
            background: #83FCD4;
        }
    }

    &--finished {

        .ac-calendar__status {
            background: #FFC778;
        }
    }

    &__status {
        position: absolute;
        top: -8px;
        left: 50%;
        padding: 5px 10px;
        width: 120px;
        text-align: center;
        background: var(--gray-10);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 10px 15px;
        text-align: center;
    }

    &__date {
        display: flex;
        flex-direction: row;

        i {
            margin-right: 5px;
            color: #5992ED;
        }

        span {
            display: inline-block;
            margin-top: -2px;
            color: #7E7E7E;
        }
    }

    button {
        margin-top: 16px;
        font-size: 12px;
        height: var(--button-xsmall);
    }
}
