.ac-contact {
    background: var(--gray-3);

    &__container {
        position: relative;

        p {
            margin: 0 !important;

            a {
                display: inline-block;
                padding-top: 5px;
                text-decoration: none !important;

                strong {
                    color: var(--blue-vivid-60);
                }
            }
        }
    }

    &__program {
        position: absolute;
        right: 50px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);

        img {
            height: 100px;
        }
    }
}


@media (max-width: 1100px) {

    .ac-contact {
        background: var(--gray-3);

        &__container {
            display: flex;
            flex-direction: column;
        }

        &__program {
            position: relative;
            right: auto;
            top: auto;
            margin-top: 30px;
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
        }
    }
}
