.ac-documentation {

    &__avatar {
        position: relative;
        min-height: 500px;

        img {
            position: absolute;
            bottom: 0;
            min-width: 400px;
            max-width: 700px;
        }
    }

    .ac-card {
        height: 350px;

        .card-header, .card-content,
        .card-footer {
            margin-top: 20px;
        }

        .card-header {

            img {
                height: 100px;
            }
        }
    }
}

@media (max-width: 991px) {
    .ac-documentation {

        &__avatar {

            img {
                left: 50%;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }
    }
}

@media (max-width: 992px) and (max-width: 1279px) {
    .ac-documentation {

        &__avatar {

            img {
                width: 370px;
            }
        }
    }
}
