html {

    body {

        &.ac-contrast {
            color: var(--pure-0) !important;
            background: var(--ac-bg-color) !important;

            .ac-oval-speech--white:after {
                background: transparent !important;
            }

            header, .menu-panel, footer, .crumb-list, .ac-contrast {
                color: var(--pure-0) !important;
                background: var(--ac-bg-color) !important;

                * {
                    color: var(--pure-0) !important;
                    background: var(--ac-bg-color) !important;
                }
            }

            h1, p.ac-contrast {
                color: var(--pure-0) !important;
            }

            .card-content {

                .ac-contrast {
                    color: var(--pure-0) !important;
                    background: transparent !important;

                    * {
                        color: var(--pure-0) !important;
                        background: transparent !important;
                    }
                }
            }

            .ac-questions, .ac-contact, .ac-program__calendar-container,
            .ac-program__faq {
                color: var(--pure-0) !important;
                background: var(--ac-bg-color) !important;
            }

            .ac-journey__bottom {
                color: var(--pure-0) !important;
                background: var(--ac-bg-color) !important;
            }

            .ac-home {

                .ac-slider__content {
                    background: var(--gray-90) !important;
                }
            }

            #ac-slider__prev, #ac-slider__next {

                * {
                    color: var(--pure-0) !important;
                }
            }

            .slick-dots {

                button:before {
                    color: var(--pure-0) !important;
                }
            }

            img.ac-contrast {
                color: transparent !important;
                background: transparent !important;
                filter: invert(1) !important;
            }

            .ac-program__content {

                p {
                    color: var(--pure-0) !important;
                }
            }

            .br-accordion {
                color: var(--pure-0) !important;
                background: var(--ac-bg-color) !important;

                .header, .header .title, .header .icon {
                    color: var(--pure-0) !important;
                }

                .content {
                    color: var(--pure-0) !important;

                    * {
                        color: var(--pure-0) !important;
                    }
                }
            }

            .ac-program-faq__menu {
                color: var(--pure-0) !important;
                background: var(--ac-bg-color) !important;
            }

            .ac-not-found {
                color: var(--pure-0) !important;

                * {
                    color: var(--pure-0) !important;
                }
            }

            .ac-card, .ac-advanced-search, .ac-about {
                color: var(--pure-0) !important;

                p {
                    color: var(--pure-0) !important;
                }
            }
        }
    }
}
