.ac-journey {

    &__top-action {
        text-align: right;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
    }

    &__avatar-container {
        position: relative;
        padding-top: 60px;
    }

    &__mobile-container {
        padding-bottom: 80px;
        padding-top: 19px;
    }

    &__mobile-card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        background: var(--pure-0);
        border: 5px solid var(--blue-vivid-50);
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        text-align: center;

        &--circle {
            padding: 40px;
            border: 25px solid var(--blue-vivid-50);
            border-radius: 100%;
            width: 320px;
            height: 320px;
            margin: auto;
            margin-top: 69px !important;

            &:before {
                top: -94px !important;
            }
        }

        & + .ac-journey__mobile-card {
            margin-top: 65px;
        }

        &:first-child {

            &:before {
                display: none !important;
            }
        }

        &:before {
            position: absolute;
            display: block;
            content: '';
            height: 70px;
            width: 20px;
            top: -70px;
            background: var(--blue-vivid-50);
        }
    }

    &__mobile-image {

    }

    &__mobile-text {

    }

    &__avatar {

        img {
            min-width: 381px;
        }
    }

    &__avatar-mobile {
        padding-top: 120px;
    }

    &__speech {
        position: absolute;
        top: 25px;
        left: 200px;
        z-index: 1;
    }

    &__worm-container {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 1550px;
    }

    &__worm {
        position: relative;
        width: 100%;
        height: 100%;

        & > img {
            position: absolute;
            top: 159px;
        }
    }

    &__final-container {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &__step {
        position: absolute;
        display: flex;
        flex-direction: row;

        img {
            max-height: 100px;
        }

        &:nth-child(2) {
            top: 230px;
            left: 235px;
        }

        &:nth-child(3) {
            flex-direction: row-reverse;
            top: 390px;
            left: -346px;
        }

        &:nth-child(4) {
            top: 555px;
            left: 189px;
        }

        &:nth-child(5) {
            flex-direction: row-reverse;
            top: 710px;
            left: -346px;
        }

        &:nth-child(6) {
            top: 845px;
            left: 235px;
        }

        &:nth-child(7) {
            flex-direction: row-reverse;
            top: 1020px;
            left: -346px;
        }

        &:nth-child(8) {
            top: 1145px;
            left: 235px;
        }

        &:nth-child(9) {
            flex-direction: row-reverse;
            top: 1295px;
            left: -346px;
        }

        &--left {

            .ac-journey__step-text {
                margin-right: 100px;

                div {

                    &:after {
                        right: -28px;
                    }
                }
            }
        }

        &--right {

            .ac-journey__step-text {
                margin-left: 100px;

                div {

                    &:after {
                        left: -28px;
                    }
                }
            }
        }
    }

    &__step-text {
        position: relative;
        width: 320px;

        &--circle {
            width: 224px;

            div {
                width: 100%;
                height: 224px;
                border-radius: 100% !important;
                padding: 60px !important;
                text-align: center;
            }
        }

        div {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            background: var(--pure-0);
            top: 50%;
            padding: 20px;
            border: 1px dashed #1351B4;
            box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            transform: translateY(-50%);

            &:after {
                position: absolute;
                display: block;
                content: '';
                width: 28px;
                height: 1px;
                background: transparent;
                border-top: 1px dashed #1351B4;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        min-height: 500px;
        background: var(--blue-10);

        .h-100 {
            flex: 1;
            height: 100%;
        }
    }

    &__bottom-avatar {
        position: absolute;
        bottom: 0;
    }

    &__bottom-avatar-mobile {
    }

    &__bottom-speech {
        position: absolute;
        top: 20px;
        left: 200px;
        z-index: 1;
    }

    &__videos {
        padding: 20px;
        min-height: 500px;
    }

    &__video {
        position: relative;
        //padding-top: 56.25%;
        margin: 10px 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding: 0 10px;
        }
    }

    p {
        font-size: 14px !important;
    }
}

@media (max-width: 991px) {
    .ac-journey{

        &__avatar-container {
            width: 100%;
        }

        &__avatar-mobile {
            text-align: center;
        }

        &__worm-container {
            height: 382px;
        }

        &__top-action {
            text-align: center;
        }

        &__bottom {
            min-height: auto;
            padding-bottom: 80px;

            .h-100 {
                flex: none;
                height: 100%;
            }
        }

        &__bottom-avatar-mobile {

        }

        &__bottom-avatar-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 370px;
        }

        &__speech {
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }

        &__bottom-speech {
            position: relative;
            top: auto;
            left: auto;
            margin-top: 40px;
        }

        &__videos {
            padding: 0px;
        }

        &__video {
            position: relative;
            padding-top: 56.25%;
            //height: 500px;
            margin: 0;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0;
            }

            & + .ac-journey__video {
                margin-top: 20px;
            }
        }

        &__mobile-card {

            &--circle {
                width: 280px;
                height: 280px;
            }
        }
    }
}

@media (min-width: 992px) {
    .ac-journey {

        &__mobile-container {
            display: none;
        }
    }
}
