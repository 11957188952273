@import "src/assets/styles/styles";

%app-height {
    height: 100%;
}

:host {
    @extend %app-height;
}

#ac-root {
    @extend %app-height;
}
