.ac-list {

    &--none {
        list-style: none;
    }

    li + li {
        margin-top: 8px;
    }

    &--roman {
        list-style-type: upper-roman;
    }

    &--latin {
        list-style-type: lower-latin;
    }
}
