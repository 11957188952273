.ac-header {

    &__dropdown {

        & > button {

            & + * {
                box-shadow: var(--surface-shadow-sm);
                left: 0;
                position: absolute;
                top: 100%;
            }
        }
    }
}

@media (min-width: 1280px) {
    .ac-header {

        &__dropdown {

            & > button {
                display: none;
            }
        }
    }
}
