.ac-oval-speech {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    padding: 20px 25px;
    text-align: center;
    color: var(--pure-0);
    background: var(--blue-10);
    border-left: 0;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    font-size: 12px;
    font-style: normal;

    &--rounded {
        -webkit-border-radius: 14px;
        -moz-border-radius: 14px;
        border-radius: 14px;

        &.ac-oval-speech--white {
            border: 1px solid var(--blue-vivid-50);

            &.ac-oval-speech--top-2 {

                &:after {
                    border-color: transparent transparent var(--blue-vivid-50);
                }

                .ac-oval-speech--white-triangle {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: -19px;
                    width: 0;
                    height: 0;
                    border-width: 0 19px 19px;
                    border-style: solid;
                    border-color: transparent transparent var(--pure-0);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    transform: translateX(-50%);
                    background: transparent !important;
                    z-index: 1;
                }
            }

            &.ac-oval-speech--left-2 {

                .ac-oval-speech--white-triangle {
                    content: "";
                    position: absolute;
                    left: -19px;
                    top: 50%;
                    width: 0;
                    height: 0;
                    border-width: 19px 19px 19px 0;
                    border-style: solid;
                    border-color: transparent var(--pure-0);
                    -webkit-transform: translateY(-50%);
                    -moz-transform: translateY(-50%);
                    -o-transform: translateY(-50%);
                    transform: translateY(-50%);
                    background: transparent !important;
                    z-index: 1;
                }
            }
        }

        &.ac-oval-speech--top-2 {
            margin-bottom: 40px !important;

            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: -20px;
                width: 0;
                height: 0;
                border-width: 0 20px 20px;
                border-style: solid;
                border-color: transparent transparent var(--blue-10);
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }

        &.ac-oval-speech--left {
            margin-bottom: 40px !important;

            &:after {
                content: "";
                position: absolute;
                left: -20px;
                top: 50%;
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-right: 20px solid var(--blue-10);
                background: transparent;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }

        &.ac-oval-speech--left-2 {
            margin-bottom: 20px !important;

            &:after {
                content: "";
                position: absolute;
                left: -20px;
                top: 50%;
                width: 0;
                height: 0;
                border-width: 19px 19px 19px 0;
                border-style: solid;
                border-color: transparent var(--blue-vivid-50);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                background: transparent !important;
            }
        }

        &.ac-oval-speech--right {
            margin-bottom: 40px !important;

            &:after {
                content: "";
                position: absolute;
                right: -20px;
                top: 50%;
                width: 0;
                height: 0;
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 20px solid var(--blue-10);
                background: transparent;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }

        &.ac-oval-speech--bottom {
            margin-bottom: 40px !important;

            &:after {
                content: "";
                position: absolute;
                bottom: -20px;
                left: 50%;
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid var(--blue-10);
                background: transparent;
                -webkit-transform: translateX(-50%);
                -moz-transform: translateX(-50%);
                -o-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }
    }

    &--white {
        background: var(--pure-0);

        &:before {
            border-right: 60px solid var(--pure-0) !important;
            background: var(--pure-0) !important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid var(--pure-0);
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid var(--pure-0);
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid var(--pure-0);
                }
            }
        }
    }

    &--white-2 {
        background: var(--pure-0);

        &:before {
            border-right: 60px solid var(--pure-0) !important;
            background: var(--pure-0) !important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid var(--pure-0);
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid var(--pure-0);
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid var(--pure-0);
                }
            }
        }
    }

    &--blue {
        background: var(--blue-10);

        &:before {
            border-right: 60px solid var(--blue-10) !important;
            background: var(--blue-10)!important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid var(--blue-10);
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid var(--blue-10);
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid var(--blue-10);
                }
            }
        }
    }

    &--blue-2 {
        background: var(--blue-20);

        &:before {
            border-right: 60px solid var(--blue-20) !important;
            background: var(--blue-20)!important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid var(--blue-20);
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid var(--blue-20);
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid var(--blue-20);
                }
            }
        }
    }

    &--blue-3 {
        background: #D4DCE7;

        &:before {
            border-right: 60px solid #D4DCE7 !important;
            background: #D4DCE7!important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid #D4DCE7;
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid #D4DCE7;
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid #D4DCE7;
                }
            }
        }
    }

    &--blue-4 {
        background: var(--blue-20);

        &:before {
            border-right: 60px solid var(--blue-20) !important;
            background: var(--blue-20) !important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid var(--blue-20);
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid var(--blue-20);
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid var(--blue-20);
                }
            }
        }
    }

    &--red {
        background: var(--red-20);

        &:before {
            border-right: 60px solid var(--red-20) !important;
            background: var(--red-20) !important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid var(--red-20);
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid var(--red-20);
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid var(--red-20);
                }
            }
        }
    }

    &--pink {
        background: var(--red-cool-20);

        &:before {
            z-index: -1;
            border-right: 60px solid var(--red-cool-20) !important;
            background: var(--red-cool-20)!important;
        }

        &:after {
            background: var(--pure-0);
        }

        &.ac-oval-speech--rounded {

            &.ac-oval-speech--left {

                &:after {
                    border-right: 20px solid var(--red-cool-20);
                }
            }

            &.ac-oval-speech--right {

                &:after {
                    border-left: 20px solid var(--red-cool-20);
                }
            }

            &.ac-oval-speech--bottom {

                &:after {
                    border-top: 20px solid var(--red-cool-20);
                }
            }
        }
    }

    &--small {
        width: 180px;
    }

    &--medium {
        width: 360px;
    }

    &--medium-alt {
        width: 280px;
    }

    &--medium-alt-2 {
        width: 240px;
    }

    &--100 {
        width: 100%;
        margin-left: 0 !important;
    }

    &--with-indicator {
        margin-bottom: 40px !important;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: -30px;
            right: 50%;
            height: 30px;
            border-right: 60px solid var(--blue-10);
            background: var(--blue-10);
            -webkit-border-bottom-right-radius: 80px 50px;
            -moz-border-radius-bottomright: 80px 50px;
            border-bottom-right-radius: 80px 50px;
            -webkit-transform: translate(0, -2px);
            -moz-transform: translate(0, -2px);
            -ms-transform: translate(0, -2px);
            -o-transform: translate(0, -2px);
            transform: translate(0, -2px);
        }

        &:after {
            content: "";
            position: absolute;
            z-index: -1;
            bottom: -30px;
            right: 50%;
            width: 60px;
            height: 30px;
            background: var(--pure-0);
            -webkit-border-bottom-right-radius: 40px 50px;
            -moz-border-radius-bottomright: 40px 50px;
            border-bottom-right-radius: 40px 50px;
            -webkit-transform: translate(-30px, -2px);
            -moz-transform: translate(-30px, -2px);
            -ms-transform: translate(-30px, -2px);
            -o-transform: translate(-30px, -2px);
            transform: translate(-30px, -2px);
        }
    }

    &--top {

        &:after {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 50%;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid var(--blue-10);
            background: transparent;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }

    &--top-left {

        &:after {
            content: "";
            position: absolute;
            bottom: -20px;
            left: 20%;
            width: 0;
            height: 0;
            border-left: 20px solid transparent;
            border-right: 20px solid transparent;
            border-top: 20px solid #D4DCE7;
            background: transparent;
        }
    }

    &--circle {
        width: 230px;
        height: 230px;
        border-radius: 50%;
        background: var(--pure-0);
        border: solid var(--blue-vivid-60) 1px;

        &.ac-oval-speech--with-indicator {

            &:before {
                content:"";
                position:absolute;
                z-index:2;
                bottom: -30px;
                right: 54%;
                width: 50px;
                height: 28px;
                border-style:solid;
                border-width:0 1px 1px 0;
                border-color:var(--blue-vivid-60);
                margin-right:-10px;
                background:transparent;
                -webkit-border-bottom-right-radius:80px 50px;
                -moz-border-radius-bottomright:80px 50px;
                border-bottom-right-radius:80px 50px;
                display:block;
            }

            &:after {
                content:"";
                position:absolute;
                z-index:2;
                bottom: -30px;
                right: 41%;
                width:20px;
                height:31px;
                border-style:solid;
                border-width:0 1px 1px 0;
                border-color:var(--blue-vivid-60);
                margin-right:20px;
                background:transparent;
                -webkit-border-bottom-right-radius:40px 50px;
                -moz-border-radius-bottomright:40px 50px;
                border-bottom-right-radius:40px 50px;
                display:block;
            }

            & > :first-child:after {
                content:"";
                position:absolute;
                z-index:1;
                bottom:-10px;
                right:50%;
                width:30px;
                height:15px;
                background:var(--pure-0);
            }
        }
    }

    &--right-indicator {
    }

    &__divider {
        position: absolute;
        width: 45px;
        height: 35px;
        background: var(--pure-0);
        top: 3.5%;
        left: 12%;
    }

    p {
        font-size: 14.5px;
    }
}
