.ac-program {

    &__calendar-container {
        display: flex;
        flex-direction: column;
        background: var(--blue-10);
    }

    &__calendar {
        width: calc(100% - 80px);

        //.ac-program__title {
        //    padding-bottom: 20px;
        //}

        //.carousel-step {
        //    display: none;
        //}
    }

    &__calendar {
        padding: 20px 0;
    }

    &__speech {
        position: absolute;
        top: -100px;
        right: 230px;
        z-index: 1;

        &--sisu {
            top: -100px;
            right: 230px;
        }

        &--prouni {
            top: -120px;
            right: 300px;
        }

        &--fies {
            top: -75px;
            right: 300px;
        }
    }

    &__avatar-desktop {
        position: relative;
        min-height: 295px;

        img {
            position: absolute;
            right: 0;
            bottom: 0;
            min-height: 295px;
        }
    }

    &__avatar-mobile {
        position: absolute;
        top: -171px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);

        img {
            min-width: 324px;
        }
    }

    &__content {

        & + .ac-program__content {
            margin-top: 40px;
        }
    }

    &__title {
        font-size: 17px;
        text-transform: uppercase;

        & + .ac-program__text {
            margin-top: 20px;
        }
    }

    &__text {

    }

    &__buttons-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 40px;

        button {
            margin: 5px;
        }
    }

    &__video {
        position: relative;
        padding-top: 56.25%;

        & + .ac-program__video {
            margin-top: 30px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__download {
        margin-top: 40px;

        button {
            width: 90%;
            margin-left: 5%;

            &, &:visited, &:active {
                font-weight: normal !important;
                color: var(--color) !important;
                background: rgb(252,221,221);
                background: -moz-linear-gradient(180deg, rgba(252,221,221,1) 0%, rgba(235,234,234,0) 100%);
                background: -webkit-linear-gradient(180deg, rgba(252,221,221,1) 0%, rgba(235,234,234,0) 100%);
                background: linear-gradient(180deg, rgba(252,221,221,1) 0%, rgba(235,234,234,0) 100%);
                background-color: var(--pure-0) !important;
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcdddd",endColorstr="#ebeaea",GradientType=1);
                box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
            }

            &:hover, &:active {

                &:after {
                    background: rgb(252,221,221) !important;
                }
            }

            i {
                color: var(--red-vivid-60) !important;
                margin-left: 5px;
            }
        }
    }

    &__faq {
        display: flex;
        flex-direction: column;
        background: var(--blue-10);

        .br-button {
            background: var(--pure-0);
            min-height: var(--button-size) !important;
            height: auto !important;
        }
    }

    &__faq-title {
        font-size: 20px;
        font-weight: 600;
    }

    &__faq-links {
        text-align: center;

        .br-button {
            min-width: 200px;
            margin: 10px 20px;
        }
    }

    &__faq-all {

        a {
            text-decoration: none !important;
        }
    }

    .br-accordion {

        .item {

            &.active {

                & + .content {
                    border-bottom: 1px solid var(--color-secondary-04);
                    display: block;
                }
            }
        }

        .title {
            padding-left: 10px;
        }

        .content {
            padding: 15px 10px 30px 10px;
            margin: 0;
        }
    }

    .ac-slider__content {
        background: transparent !important;
    }

    &__button {

        &--hide {
            display: none;
        }
    }

    &__bottom-alert {
        margin-top: 20px;
        color: var(--red-50);
        font-style: italic;
    }
}

@media (max-width: 991px) {

    .ac-program {

        &__title_container {
            min-height: 350px;

            &--sisu {
                min-height: 350px;
            }

            &--prouni {
                min-height: 410px;
            }

            &--fies {
                min-height: 370px;
            }
        }

        &__speech {
            position: absolute;
            top: -280px;
            left: 50%;
            margin-left: -139px;
            z-index: 1;

            &--sisu {
                top: -315px;
            }

            &--prouni {
                top: -335px;
            }

            &--fies {
                top: -295px;
            }
        }

        &__calendar-container {
            margin-bottom: 80px;
        }

        &__calendar {
            width: 100%;
        }

        &__faq {
            display: flex;
            flex-direction: column;
            background: var(--blue-10);

            .br-button {
                background: var(--pure-0);
                min-height: var(--button-size) !important;
                height: auto !important;
                max-width: 400px;

                span {
                    display: block;
                    width: 100%;
                    margin: 0;
                    word-break: keep-all;
                    text-align: center;
                    height: 100%;
                    white-space: normal;
                    padding: 10px 0;
                }
            }
        }
    }
}
