.ac-about {

    &__top-action {
        text-align: right;
    }

    &__content {

        & + .ac-about__content {
            margin-top: 40px;
        }
    }

    &__title {
        font-size: 17px;
        text-transform: uppercase;

        & + .ac-about__text {
            margin-top: 20px;
        }
    }

    &__text {

    }

    &__video {
        position: relative;
        padding-top: 56.25%;

        & + .ac-about__video {
            margin-top: 30px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__timeline {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 10px;
        min-height: 310px;

        .ac-about__timeline-item {

            &:nth-child(odd) {
                margin-bottom: 40px;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 14px;
                    height: 11px;
                    background: #F3F3F3;
                    left: 3px;
                    top: 70%;
                    transform: rotateY(0deg) rotate(153deg);
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 14px;
                    height: 11px;
                    background: #F3F3F3;
                    right: 3px;
                    top: 70%;
                    transform: rotateY(0deg) rotate(27deg);
                }
            }

            &:nth-child(even) {
                margin-top: 40px;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 19px;
                    height: 11px;
                    background: #F3F3F3;
                    left: -5px;
                    top: 23.9px;
                    transform: rotateY(0deg) rotate(207deg);
                }

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 19px;
                    height: 11px;
                    background: #F3F3F3;
                    right: -5px;
                    top: 23.9px;
                    transform: rotateY(0deg) rotate(153deg);
                }

                .ac-about__timeline-here {
                    top: -90px;
                    left: -90px;
                    width: 80px;

                    &:after {
                        width: 90px;
                        left: 40px;
                        top: 70px;
                    }
                }

                .ac-about__timeline-info {
                    flex-direction: column-reverse;
                    top: -125px;
                }
            }

            &:first-child {

                &:before {
                    display: none;
                }
            }

            &:last-child {

                &:after {
                    display: none;
                }
            }
        }
    }

    &__timeline-item {
        position: relative;
        padding: 0 10px;

        &--filled {

            &:before, &:after {
                background: #D8D8D8 !important;
            }

            .ac-about__timeline-circle {
                background: linear-gradient(180deg, #A5A6F6 0%, #DEB1A6 100%);
                border: solid 11px #D8D8D8;

                i {
                    color: var(--pure-0);
                }
            }

            .ac-about__timeline-info {

                &:before {
                    background: var(--blue-vivid-60) !important;
                }

                span {

                    &:first-child {
                        color: var(--blue-vivid-60) !important;
                    }

                    &:last-child {
                        color: var(--blue-vivid-60) !important;
                    }
                }
            }
        }

        &--active {

            .ac-about__timeline-here {
                display: block;
            }

            .ac-about__timeline-info {

                span {
                    color: var(--blue-vivid-50) !important;
                }
            }
        }

        &--active, &--filled {

            .ac-about__timeline-circle {
                background: var(--blue-vivid-50);
                border: solid 11px var(--blue-vivid-50) !important;
            }

            &:before, &:after {
                background: var(--blue-vivid-50) !important;
            }
        }
    }

    &__timeline-here {
        display: none;
        position: absolute;
        font-style: italic;
        font-size: 12px;
        top: -60px;
        left: -20px;
        width: 80px;

        &:after {
            content: '';
            display: block;
            position: absolute;
            width: 45px;
            height: 1px;
            background: var(--text-color);
            left: 12px;
            top: 55px;
            transform: rotateY(0deg) rotate(45deg);
        }
    }

    &__timeline-circle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: transparent;
        border: solid 11px #F3F3F3;

        i {
            width: 35px;
            height: 35px;
            font-size: 31px;
            color: #C2C2C2;
        }
    }

    &__timeline-info {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 120px;

        &:before {
            content: '';
            width: 2px;
            height: 8px;
            background: #7E7E7E;
            margin-top: 6px;
        }

        span {

            &:first-child {
                font-size: 30px;
                font-weight: 600;
                color: #7E7E7E;
            }

            &:last-child {
                text-align: center;
                font-size: 12px;
                width: 130px;
                color: #7E7E7E;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }
}

@media (max-width: 500px) {
    .ac-about {

        &__timeline {

            .ac-about__timeline-item {

                &:nth-child(even) {

                    .ac-about__timeline-here {
                        left: 5px !important;
                    }
                }
            }
        }

        &__timeline-here {
            left: 5px !important;
        }
    }
}

@media (max-width: 760px) {
    .ac-about {

        &__timeline {
            flex-direction: column;

            .ac-about__timeline-item {

                &:nth-child(odd) {
                    margin-bottom: 0;

                    &:before {
                        display: none;
                    }

                    &:after {
                        display: none;
                    }
                }

                &:nth-child(even) {
                    margin-top: 0;

                    &:before {
                        display: none;
                    }

                    &:after {
                        display: none;
                    }

                    .ac-about__timeline-here {
                        top: 30px;
                        left: 15%;

                        &:after {
                            width: 45px;
                            left: 12px;
                            top: 55px;
                        }
                    }

                    .ac-about__timeline-info {
                        flex-direction: column;
                        top: auto;
                    }
                }
            }
        }

        &__timeline-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: relative;
            padding: 40px 20px;
            border-bottom: 1px solid #DFDFDF;

            &:last-child {
                border-bottom: 0;
            }
        }

        &__timeline-here {
            top: 30px;
            left: 15%;
        }

        &__timeline-circle {
            width: 100px;
            height: 100px;
            background: #F3F3F3;
            border: 0 !important;
            margin-right: 0 !important;

            i {
                width: 50px;
                height: 50px;
                font-size: 44px;
            }
        }

        &__timeline-info {
            position: relative;
            padding: 0;
            margin-top: 15px;
            left: auto;
            -webkit-transform: translateX(0);
            -moz-transform: translateX(0);
            -o-transform: translateX(0);
            transform: translateX(0);
            height: auto;

            &:before {
                display: none;
            }
        }
    }
}

@media (max-width: 991px) {
    .ac-about {

        &__top-action {
            text-align: center;
        }
    }
}
