.ac-slider {
    position: relative;

    &--center {

        .glider-slide {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    &--home {
        height: 300px;

        .slick-slider {
            height: 300px;

            .slick-list {
                height: 300px;
            }

            .slick-track {
                height: 300px;
            }

            .slick-slide {
                background: #C4D3EA;
                background: -moz-linear-gradient(180deg, #C4D3EA 0%, #FCF4E5 100%);
                background: -webkit-linear-gradient(180deg, #C4D3EA 0%, #FCF4E5 100%);
                background: linear-gradient(180deg, #C4D3EA 0%, #FCF4E5 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#C4D3EA",endColorstr="#FCF4E5",GradientType=1);
            }

            .slick-dots {
                bottom: -30px !important;
            }
        }
    }

    &--program {

        .slick-slider {

            .slick-prev, .slick-next {
                height: 100px !important;
                width: 40px !important;

                img {
                    height: 100%;
                }
            }

            .slick-prev {
                left: -55px !important;
            }

            .slick-next {
                right: -55px !important;
            }

            .slick-dots {
                display: none !important;
                bottom: -75px !important;
            }
        }
    }

    &--hide-dots {

        .glider-dots {
            display: none;
            position: absolute;
            left: 50%;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            transform: translateX(-50%);
            bottom: -60px;
        }
    }

    &__content {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        height: 100%;

        &--no-padding {
            padding: 0 !important;

            iframe {
                width: 100%;
                height: 100%;
            }
        }

        span + a, span + div {
            margin-top: 30px;
        }
    }

    &__prev {
        position: absolute;
        top: 50%;
        left: -50px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer;
        color: var(--blue-vivid-60);
        z-index: 1;
    }

    &__next {
        position: absolute;
        top: 50%;
        right: -49px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        cursor: pointer;
        color: var(--blue-vivid-60);
        z-index: 1;
    }

    .glider {

        .glider {
            //width: 100% !important;
            overflow: hidden;
        }
    }

    .glider-contain {
    }

    .glider-slide {
    }

    .glider-track {
        //width: 100% !important;
    }

    .glider-dots {
        margin-top: 10px;

        .glider-dot {
            background: #CCCCCC;

            &.active {
                background: #071D41;
            }
        }
    }

    .glider-track {
        position: relative;
    }

    .slick-slider {

        .slick-slide {

            .ac-slider__content {
                padding: 15px;
            }

            & > div {
                height: 100%;
            }
        }

        .slick-prev, .slick-next {
            color: var(--blue-vivid-60);

            &:before {
                display: none !important;
            }
        }

        .slick-dots {

            li {
                width: 23px !important;
                height: 23px !important;

                button {
                    width: 23px !important;
                    height: 23px !important;

                    &:before {
                        font-size: 14px !important;
                        width: 23px !important;
                        height: 23px !important;
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {

    .ac-slider {

        &__prev {
            display: none !important;
        }

        &__next {
            display: none !important;
        }

        &--program {

            .slick-slider {

                .slick-dots {
                    display: block !important;
                }
            }
        }
    }
}
