.ac-programs {

    .ac-card {
        min-height: 520px;

        .card-header, .card-content,
        .card-footer {
            margin-top: 20px;
        }

        .card-header {

            img {
                height: 100px;
            }
        }

        .card-content {
            text-align: left;
        }
    }

    &__button {

        &--hide {
            display: none;
        }
    }
}
