.ac-card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
    color: var(--text-color);

    &--blue {
        background: rgb(221,228,252);
        background: -moz-linear-gradient(180deg, rgba(221,228,252,1) 0%, rgba(235,234,234,0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(221,228,252,1) 0%, rgba(235,234,234,0) 100%);
        background: linear-gradient(180deg, rgba(221,228,252,1) 0%, rgba(235,234,234,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dde4fc",endColorstr="#ebeaea",GradientType=1);
    }

    &--secondary-blue {
        background: rgb(197, 212, 235);
        background: -moz-linear-gradient(180deg, rgba(197,212,235,0.8) 0%, rgba(196,196,196,0.33) 100%);
        background: -webkit-linear-gradient(180deg, rgba(197,212,235,0.8) 0%, rgba(196,196,196,0.33) 100%);
        background: linear-gradient(180deg, rgba(197,212,235,0.8) 0%, rgba(196,196,196,0.33) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c5d4eb",endColorstr="#c4c4c4",GradientType=1);
    }

    &--gray {
        background: rgb(235,234,234);
        background: -moz-linear-gradient(180deg, rgba(235,234,234,1) 0%, rgba(235,234,234,0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(235,234,234,1) 0%, rgba(235,234,234,0) 100%);
        background: linear-gradient(180deg, rgba(235,234,234,1) 0%, rgba(235,234,234,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ebeaea",endColorstr="#ebeaea",GradientType=1);
    }

    &--pink {
        background: rgb(248,221,252);
        background: -moz-linear-gradient(180deg, rgba(248,221,252,1) 0%, rgba(235,234,234,0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(248,221,252,1) 0%, rgba(235,234,234,0) 100%);
        background: linear-gradient(180deg, rgba(248,221,252,1) 0%, rgba(235,234,234,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8ddfc",endColorstr="#ebeaea",GradientType=1);
    }

    &--green {
        background: rgb(221,252,233);
        background: -moz-linear-gradient(180deg, rgba(221,252,233,1) 0%, rgba(235,234,234,0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(221,252,233,1) 0%, rgba(235,234,234,0) 100%);
        background: linear-gradient(180deg, rgba(221,252,233,1) 0%, rgba(235,234,234,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ddfce9",endColorstr="#ebeaea",GradientType=1);
    }

    &--red {
        background: rgb(252,221,221);
        background: -moz-linear-gradient(180deg, rgba(252,221,221,1) 0%, rgba(235,234,234,0) 100%);
        background: -webkit-linear-gradient(180deg, rgba(252,221,221,1) 0%, rgba(235,234,234,0) 100%);
        background: linear-gradient(180deg, rgba(252,221,221,1) 0%, rgba(235,234,234,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fcdddd",endColorstr="#ebeaea",GradientType=1);
    }

    .card-header {
        font-weight: 800;
        font-size: 24px;
        color: var(--blue-vivid-60);
    }

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;

        p {
            font-weight: normal;
            font-size: 14.5px;
        }
    }
}
