.header-title {
    & > a {
        color: var(--header-title-color) !important;
    }
}

.main-content {
    flex: 1 !important;
}

.menu-item {
    border-bottom: 1px solid var(--menu-divider);
}

.br-magic-button .br-button.primary {
    background-color: var(--interactive);
}

@media (max-width: 574px) {

    button {
        font-size: 14px !important;
    }

    .crumb {
        padding-left: 33px;

        &.menu-mobil, &.home {
            padding-left: 0;
        }
    }
}
