.ac-home {

    &__top {
        display: flex;
        align-items: center;
        padding-bottom: 30px;
        border-bottom: 1px solid #D8D8D8;
        height: 148px;

        a {

            &:hover {
                background: transparent !important;
            }
        }
    }

    &__text {
        color: var(--text-color);
        text-decoration: none !important;
        font-size: 48px;
    }

    &__programs-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        img {
            height: 70px;
        }
    }

    &__welcome-speech {
        position: relative;
        margin-top: 30px;
        flex: 1;
    }

    &__welcome-mobile-speech {
        display: none !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__avatar {
        position: absolute;
        bottom: calc(-1 * var(--spacing-scale-5x));
        left: 40px;
    }

    &__top_speech {
        position: absolute;
        top: 0;
        z-index: 1;
    }

    &__right-speech {
        position: absolute;
        top: 200px;
        left: 58%;
        z-index: 1;
    }

    &__first-speech {
        margin-left: 40px !important;
    }

    &__second-speech {
    }

    &__third-speech {
    }

    &__fourth-speech {
        position: absolute;
        margin-top: -90px !important;
        margin-left: 131px !important;
    }

    &__programs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        img {
            height: 50px;
        }
    }

    &__highlight-title {
        min-height: var(--spacing-scale-4x);
        font-weight: normal;
        font-size: 24.19px;
        text-align: center;
    }

    .ac-card {
        min-height: 360px;

        .card-content {
            padding: 0;

            img {
                height: 80px;
                margin-bottom: 20px;
            }
        }

        .card-footer {

            .br-button {
                width: 100%;
            }
        }
    }

    .carousel-content > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 300px;

        strong {
            display: block;
            margin-bottom: 30px;
            text-align: center;
        }
    }

    .ac-calendar {
        max-width: 250px;
    }
}

@media (max-width: 991px) {

    .ac-home {

        &__top {
            text-align: center;
        }

        &__welcome-speech {
            display: none;
        }

        &__welcome-mobile-speech {
            display: flex !important;
        }

        &__programs {
            display: flex !important;
            flex-direction: column;

            a + a {
                margin-top: 30px;
            }

            img {
                height: 80px;
            }
        }

        .ac-card {
            margin-top: 40px;
        }

        &__highlight-title {
            margin-top: 20px;

            &--programs {
                margin-top: 0;
            }
        }

        .ac-slider {
            height: 400px;
            margin-bottom: 110px;

            .slick-slider {
                height: 500px;

                .slick-list {
                    height: 500px;
                }

                .slick-track {
                    height: 500px;
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1599px) {

    .ac-home {

        &__welcome-speech {
            width: 100%;
            min-height: 660px;
        }

        &__programs {
            margin-top: 100px;
        }

        .ac-card {
            margin-top: 40px;
        }

        &__highlight-title {
            margin-top: 20px;
        }

        .ac-slider {
            height: 500px;

            .slick-slider {
                height: 500px;

                .slick-list {
                    height: 500px;
                }

                .slick-track {
                    height: 500px;
                }
            }
        }
    }
}
