.ac-questions {
    display: flex;
    flex-direction: column;
    background: var(--blue-10);

    .br-button {
        background: var(--pure-0);
    }

    &__faq-title {
        font-size: 20px;
        font-weight: 600;
    }

    &__faq-links {
        padding-top: 20px;

        .br-button {
            margin: 10px 20px;
        }
    }
}
