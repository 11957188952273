.ac-breadcrumb {

    .crumb {

        .br-button {
            display: flex !important;
            justify-content: center;
            align-items: center;
        }
    }
}
