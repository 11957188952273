%app-height {
    height: 100%;
}

:host {
    @extend %app-height;
}

.ac-app {

    main {
        display: flex;
        flex-direction: column;
        @extend %app-height;

        &.ac-page-wrapper {
            flex-direction: column;
        }
    }
}
